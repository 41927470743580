<template>
  <div class="play-tab df-center">
    <div class="item flex-1" @click="onPreNext(false)" :class="{ disabled: unitIndex === 0 }">
      <zm-icon icon-class="previousLine" class="fs28"></zm-icon>
      <div class="name">上一单元</div>
    </div>
    <div class="item flex-1" @click="onReview">
      <zm-icon icon-class="circleArrowRight" class="fs32"></zm-icon>
      <div class="name">单词学习</div>
    </div>
    <div class="item flex-1" @click="initPlayArr">
      <zm-icon :icon-class="isPlaying ? 'circlePause' : 'circlePlay'" class="circle-play"></zm-icon>
      <!-- <div class="name"></div> -->
    </div>
    <div class="item flex-1" @click="onRepeat">
      <zm-icon icon-class="circleArrowLeft" class="fs32"></zm-icon>
      <div class="name">本页重读</div>
    </div>
    <div class="item flex-1" @click="onPreNext(true)" :class="{ disabled: isLastUnit }">
      <zm-icon icon-class="nextLine" class="fs28"></zm-icon>
      <div class="name">下一单元</div>
    </div>
    <!-- <a href="javascript:;" @click="closePlay">关闭</a> -->
    <div ref="audioBox" class="audio-box">
      <!-- <audio></audio> -->
    </div>
    <div class="control-speed">
      <span>慢</span>
      <van-slider v-model="audioSpeed" vertical :step="0.1" @change="changeSpeed" class="mtb15" :max="2" :min="0.2" />
      <span>快</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PlayTab',
  components: {},
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    playNext: {
      type: Boolean,
      default: false
    },
    audioArr: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currentSlide: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      myAudio: new Audio(),
      reverseAudioArr: [],
      refAudio: null,
      // innerPlaying: false,
      // isPlaying: false,
      audioSpeed: 1,
      isPauseing: false
    };
  },
  computed: {
    audioBox() {
      return this.$refs.audioBox;
    },
    isLastUnit() {
      return this.unitListLength - 1 === this.unitIndex;
    },
    ...mapGetters(['unitIndex', 'unitListLength'])
  },
  watch: {
    playNext(val) {
      // if (val) {
      console.log('playNext');
      this.initPlayArr();
      // }
    },
    reverseAudioArr(val) {
      console.log(val.length, this.audioArr.length);
    },
    currentSlide() {
      this.genPlayArr();
    },
    isPlaying(val) {
      if (!val) {
        this.closePlay();
      } else {
        this.updateIsPlaying(true);
        console.log('re is playing');
      }
    }
  },
  destroyed() {
    this.closePlay();
  },
  created() {},
  methods: {
    onPreNext(type) {
      if (type) {
        if (this.isLastUnit) return;
      } else {
        if (this.unitIndex === 0) return;
      }
      this.$emit('change-unit', type);
    },
    onReview() {
      this.$emit('to-word');
    },
    async initPlayArr() {
      if (!this.isPauseing) {
        if (this.isPlaying) {
          this.pausePlay();
        } else {
          if (!this.reverseAudioArr.length) {
            await this.genPlayArr();
            this.onPlay();
          } else {
            this.onPlay();
          }
        }
      }
    },
    onPlay() {
      if (!this.reverseAudioArr.length) return;
      let vm = this;
      vm.refAudio = new Audio();
      vm.refAudio.removeEventListener('ended', playEndedHandler, false);
      // this.isPlaying = true;
      this.updateIsPlaying(true);
      vm.refAudio.preload = true;
      vm.refAudio.controls = true;
      vm.refAudio.src = vm.reverseAudioArr.pop(); // 每次读数组最后一个元素
      vm.refAudio.playbackRate = this.audioSpeed;
      vm.refAudio.addEventListener('ended', playEndedHandler, false);
      setTimeout(() => {
        vm.refAudio.play();
      }, 150);
      console.log(vm.$refs.audioBox);
      !vm.refAudio && vm.$refs.audioBox.appendChild(vm.refAudio);
      vm.refAudio.loop = false; // 禁止循环，否则无法触发ended事件
      function playEndedHandler() {
        if (!vm.refAudio) return;
        vm.refAudio.src = vm.reverseAudioArr.pop();
        setTimeout(() => {
          vm.refAudio && vm.refAudio.play();
        }, 150);
        if (!vm.reverseAudioArr.length) {
          vm.refAudio.removeEventListener('ended', playEndedHandler, false); // 只有一个元素时解除绑定
          vm.updateIsPlaying(false);
          vm.$emit('next', false);
        }
      }
      this.$emit('play');
    },
    updateIsPlaying(type) {
      this.$emit('update:isPlaying', type);
    },
    changeSpeed(command) {
      this.$toast(`语速：${command}倍`);
      if (this.refAudio) {
        this.refAudio.playbackRate = command;
      }
      this.audioSpeed = command;
      this.$emit('speed', command);
    },
    pausePlay() {
      if (this.refAudio) {
        this.isPauseing = true;
        let time = this.refAudio.duration;
        let currentTime = this.refAudio.currentTime;
        let currentSrc = this.refAudio.src;
        if (!this.reverseAudioArr.includes(currentSrc)) this.reverseAudioArr.push(currentSrc);
        // this.closePlay();
        this.updateIsPlaying(false);
        let timer = setTimeout(() => {
          // this.closePlay();
          this.isPauseing = false;
          timer = null;
          clearTimeout(timer);
        }, (time - currentTime) * 1000);
      }
    },
    closePlay() {
      this.refAudio && this.refAudio.pause();
      let audioBox = this.audioBox;
      audioBox && audioBox.childNodes.length && audioBox.removeChild(audioBox.childNodes[0]);
      this.refAudio = null;
    },
    async onRepeat() {
      this.updateIsPlaying(false);
      await this.genPlayArr();
      this.onPlay();
    },
    genPlayArr() {
      return new Promise((resolve, reject) => {
        this.reverseAudioArr = this.audioArr.slice(0).reverse();
        // 黑科技解决最后一个不播放的问题
        this.reverseAudioArr.unshift('test');
        resolve();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.play-tab {
  background: darken($--color-primary, 5%);
  padding: 0.2rem 0.1rem;
  overflow: visible;
  .control-speed {
    position: fixed;
    display: flex;
    height: 40%;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    margin-right: 0.2rem;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }
  .circle-play {
    font-size: 1rem;
  }
  .item {
    text-align: center;
    font-size: 0.24rem;
    color: #fff;
    &.disabled {
      color: lighten($--color-primary, 10%);
    }
  }
  .audio-box {
    display: none;
  }
}
</style>
